// Module
import * as React from "react";

// Components
import Trailer from "./a-trailer/a-trailer";
import FindBusiness from "./b-findBusiness/b-findBusiness";
import ConnectWithUs from "./c-connectWithUs/c-connectWithUs";
import TrustedPartner from "./d-trustedPartner/d-trustedPartner";
import Hero, {
    headerStyle,
    IHeroProps
} from "src/components/Organisms/Hero/hero";
import Subscription from "src/components/Organisms/Subscription/subscription";
// Style
import "./homepage.scss";
import { withTranslation, WithTranslation } from 'react-i18next';
import { t } from "i18next";
import BaiduMap_DongguanMap from "./b-findBusiness/baidu_BaiduMap_DongguanMap";
import BaiduMap_WanChaiMap from "./b-findBusiness/baidu_BaiduMap_WanChaiMap";

// Taken from components/hero
const heroProps: IHeroProps = {
    style: headerStyle(require("src/components/Organisms/images/banner/homepage.jpg")),
    header: "Admin services, resources & network to help business focus",
    subLine: "Welcome to Centre O, the Hong Kong Resource Center.",
    buttonLink: "/services"
};

interface IProps extends WithTranslation {
    prop: any;
}

interface IState {
    state: any;
}

class Homepage extends React.PureComponent<IProps , IState
> {
    public render() {
        return (
            <>
                <Hero {...heroProps} />
                {/* Start of Watch the trailer */}
                <Trailer />
                {/* End of Watch the trailer */}

                {/* Start of Find business's needs */}
                <FindBusiness />
                {/* Emd of Find business's needs */}

                {/* Start of Connect with us */}
                <ConnectWithUs />
                {/* Emd of Connect with us */}

                {/* Start of subscription */}
                <Subscription />
                {/* End of subscription */}
                <div className="container" style={{display:"flex" ,justifyContent:"space-around",flexWrap:'wrap'}}>
                    <div style={{fontSize:"26px"}}>
                        <b>{t(`footer.Wanchai`)}</b>
                        <BaiduMap_WanChaiMap></BaiduMap_WanChaiMap>
                    </div>
                    <div style={{fontSize:"26px"}}>
                        <b>{t(`footer.Dongguan`)}</b>
                        <BaiduMap_DongguanMap></BaiduMap_DongguanMap>
                    </div>
                </div>
                <br></br>
                <br></br>
                {/* Start of Trusted partner */}
                <TrustedPartner />
                {/* Emd of Trusted partner */}
            </>
        );
    } 
}


export default withTranslation('info')(Homepage)
