// Module
import * as React from "react";
// Style
import "./c-connectWithUs.scss";

// business needs
import blog from "./img/blog.jpg";
import meetup from "./img/meetup.png";
import linkedin from "./img/linkedin.svg";
import facebook from "./img/facebook.png";
import meeting from "./img/AL.190207.COH.WEBW.Connect5.jpg";
import { Link } from "gatsby";
import { useTranslation, withTranslation, WithTranslation } from 'react-i18next';

interface IProps extends WithTranslation {
    prop: any;
  }
  
  interface IState {
    state: any;
  }

const ConnectWithUs = ({},{
    prop,
}:IProps) => {
    const { t } = useTranslation('info');
    return (
        <div className="container-fluid section-padding-tb96">
            <div className="container">
                <h2 className="section-header all-cap">
                    <bdi className="section-header all-cap text-highlight">
                        {t(`ConnectWithUs.title`)}{" "}
                    </bdi>
                    {t(`ConnectWithUs.SubTitle`)}
                </h2>
                <div className="row connectWithUs">
                    <div className="col-sm-12 col-md-3 itemCol">
                        <Link to="/blog">
                            <img
                                className="itemCol__img rounded"
                                src={blog}
                                alt="Blog"
                                height={170}
                                style={{width: 'auto'}}
                            />
                            <span className="itemCol__subhead   body-short-texts">
                                {t(`ConnectWithUs.Blogs`)}
                            </span>
                        </Link>
                    </div>
                    <div className="col-sm-12 col-md-3 itemCol">
                        <a href="https://www.meetup.com/Multi-Network-Business-Community/" target="_blank">
                            <img
                                className="itemCol__img rounded"
                                src={meetup}
                                alt="Meetup"
                                height={170}
                                style={{width: 'auto'}}
                            />
                            <span className="itemCol__subhead   body-short-texts">
                                {t(`ConnectWithUs.meetup`)}
                            </span>
                        </a>
                    </div>
                    <div className="col-sm-12 col-md-3 itemCol">
                        <a href="https://www.linkedin.com/company/centre-o/" target="_blank">
                            <img
                                className="itemCol__img rounded"
                                src={linkedin}
                                alt="LinkedIn"
                                height={170}
                                style={{width: 'auto'}}
                            />
                            <span className="itemCol__subhead   body-short-texts">
                                {t(`ConnectWithUs.Linkedln`)}
                            </span>
                        </a>
                    </div>
                    <div className="col-sm-12 col-md-3 itemCol">
                        <a href="https://www.facebook.com/centreobusinesscentre/" target="_blank">
                            <img
                                className="itemCol__img rounded"
                                src={facebook}
                                alt="Facebook"
                                height={170}
                                style={{width: 'auto'}}
                            />
                            <span className="itemCol__subhead   body-short-texts">
                                {t(`ConnectWithUs.Facebook`)}
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ConnectWithUs;
