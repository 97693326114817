// Module
import * as React from "react";

// Style
import "./b-findBusiness.scss";

// business needs
import globe from "./img/global.png";
import china from "./img/china.png";
import sprout from "./img/sprout.png";
import coinhand from "./img/coinHand.png";

// Components
import CustomLink from "src/components/Atoms/CustomLink/customLink";
import { useTranslation, withTranslation, WithTranslation } from 'react-i18next';


interface IProps extends WithTranslation {
    prop: any;
}

interface IState {
    state: any;
}

const BusinessCard = (props: any) => {
    return (
        <div className="col-sm-12 col-md-6 col-lg-3 p-3 itemCol">
            <img
                className="itemCol__icon card-img-top"
                src={props.img}
                alt=""
            />
            <div className="card-body itemCol__body">
                <h4 className="itemCol__body__subhead  overline-header-bold">
                    {props.header}
                </h4>
                <span className="itemCol__body__paragraph">{props.body}</span>
            </div>
            <div className="itemCol__footer">
                <CustomLink link={props.link} variation="default" />
            </div>
        </div>
    );
};



const FindBusiness = ({ },{
    prop,
}: IProps) => {
    const { t } = useTranslation('info');
    // console.log(t)
    // console.log(prop)
    return (
        <div className="container-fluid section-padding-tb96">
            <div className="container">
                <h2 className="section-header all-cap">
                    {t(`FindBusiness.Title`)}
                    <br></br>
                    <bdi className="section-header all-cap text-highlight">
                        {" "}
                        {t(`FindBusiness.SubTitle`)}
                    </bdi>
                </h2>
                <div className="row card-deck">
                    <BusinessCard
                        header={t(`FindBusiness.OPENING-A-BUSINESS-IN-HK.title`)}
                        img={sprout}
                        body={
                            t(`FindBusiness.OPENING-A-BUSINESS-IN-HK.description`)
                        }
                        link={"/open"}
                    />
                    <BusinessCard
                        header={t(`FindBusiness.BUSINESS-UPKEEPING.title`)}
                        img={coinhand}
                        body={
                            t(`FindBusiness.BUSINESS-UPKEEPING.description`)
                        }
                        link={"/open"}
                    />
                    <BusinessCard
                        header={t("FindBusiness.EXPANDING-BUSINESS.title")}
                        img={globe}
                        body={
                            t(`FindBusiness.EXPANDING-BUSINESS.description`)
                        }
                        link={"/open"}
                    />
                    <BusinessCard
                        header={t("FindBusiness.BUSINESS-ENTERING-CHINA.title")}
                        img={china}
                        body={
                            t(`FindBusiness.BUSINESS-ENTERING-CHINA.description`)
                            }
                        link={"/open"}
                    />
                </div>
            </div>
        </div>
    );
};

export default FindBusiness; // withTranslation(`info`);

