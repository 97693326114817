import React, { useEffect, useState } from 'react';

import loadable from '@loadable/component'
export default function BaiduMap_WanChaiMap( ){
    if(typeof window == "undefined"){
        return <div>

        </div>
    }
    const OtherComponent = loadable(() => import('./baiduMap_WanChai'))
    return (<div>{!!window ? <OtherComponent/> : <></>}</div>)
}