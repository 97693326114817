// Module
import * as React from "react";

// Image assets
import capsule from "./img/AL.190207.COH.WEBW.CapsuleLogoResized.png";
import dbs from "./img/AL.190207.COH.WEBW.DBSLogoResized.png";
import hangseng from "./img/AL.190207.COH.WEBW.HangSengLogoResized.png";
import boc from "./img/AL.190207.COH.WEBW.BOC.png";
import hsbc from "./img/AL.190207.COH.WEBW.HSBCLogoResized.png";
import ocbc from "./img/AL.190207.COH.WEBW.OCBCLogoResized.png";
import xero from "./img/AL.190207.COH.WEBW.XeroLogoResized.png";
import abc from "./img/ABC_logo.png";

import { useTranslation, withTranslation, WithTranslation } from 'react-i18next';


interface IProps extends WithTranslation {
    prop: any; 
}

interface IState {
    state: any;
}


const TrustedPartner = ({ } ,{
    prop,
}:IProps) => {
    const { t } = useTranslation('info');
    return (
        <div className="container-fluid section-padding-tb80">
            <div className="container">
                <h2 className="section-header all-cap">
                    {t(`TrustedPartner.title`)}<bdi className="text-highlight"> {t(`TrustedPartner.subTitle`)}</bdi>
                </h2>
                <div className="row">
                    <div className="col-sm-6 col-md-3 itemCol">
                        <img className="itemCol__img" src={capsule} alt="" />
                    </div>
                    <div className="col-sm-6 col-md-3 itemCol">
                        <img className="itemCol__img" src={dbs} alt="" />
                    </div>
                    <div className="col-sm-6 col-md-3 itemCol">
                        <img className="itemCol__img" src={hangseng} alt="" />
                    </div>
                    <div className="col-sm-6 col-md-3 itemCol">
                        <img className="itemCol__img" src={boc} alt="" />
                    </div>
                    <div className="col-sm-6 col-md-3 itemCol">
                        <img className="itemCol__img" src={hsbc} alt="" />
                    </div>
                    <div className="col-sm-6 col-md-3 itemCol">
                        <img className="itemCol__img" src={ocbc} alt="" />
                    </div>
                    <div className="col-sm-6 col-md-3 itemCol">
                        <img className="itemCol__img" src={xero} alt="" />
                    </div>
                    <div className="col-sm-6 col-md-3 itemCol">
                        <img className="itemCol__img" src={abc} alt="" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TrustedPartner;
