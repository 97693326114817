import * as React from "react";

import Homepage from 'src/containers/1-homepage/homepage';


export default () => {
  
  // const stripeToken = process.env.GATSBY_STRIPE_API_TOKEN || ''
  // const stripeToken = process.env.GATSBY_STRIPE_TEST_KEY || ''
  const stripeToken = process.env.GATSBY_STRIPE_LIVE_KEY || ''
  // console.log({stripeToken});
  return <Homepage />;
}