// Module
import * as React from "react";

// Style
import "./a-trailer.scss";

// Components
import CustomButton from "src/components/Atoms/CustomButton/customButton";
import { Modal, ModalBody } from "reactstrap";
import { withTranslation, WithTranslation } from 'react-i18next';
import i18next, { t } from "i18next";

interface IProps extends WithTranslation {
    prop: any;
  }
  
  interface IState {
    state: any;
  }

class Trailer extends React.Component<{},{ modal: boolean } , {props:IProps, state:IState} > {
    constructor(props: {}) {
        super(props);
        this.state = {
            modal: false
        };

        this.toggle = this.toggle.bind(this);
    }

    public toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    public render() {
        return (
            <div className="container-fluid section-padding-tb96">
                <div className="container rounded" id="trailer">
                    <h2 className="all-cap text-light">
                        <bdi className="text-highlight">Centre O</bdi> {t(`trailer.Title`)}
                    </h2>
                    <p className="text-light">
                    {t(`trailer.description`)}{" "}
                    </p>
                    <CustomButton
                        text={t(`trailer.WATCH-THE-TRAILER.title`)}
                        fnTrigger={this.toggle}
                        link=""
                        variation="theme"
                    />
                    <Modal
                        isOpen={this.state.modal}
                        toggle={this.toggle}
                        centered={true}
                        className="pop-modal modal-lg"
                    >
                        <ModalBody>
                            <div className="embed-responsive embed-responsive-16by9 w-100">
                                <iframe
                                    className="embed-responsive-item"
                                    src="https://www.youtube.com/embed/xqzML__jKyU"
                                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                                />
                            </div>
                        </ModalBody>
                    </Modal>
                </div>
            </div>
        );
    }
}

export default withTranslation('info')(Trailer)